import { API } from "aws-amplify";
import { APP_AUTH_MODE } from "../constants/app";
import { getGovernment as getGovernmentCmd, listDepartments } from "../graphql/queries";
import { updateGovernment as updateGovernmentCmd } from "../graphql/mutations";

export const getGovernment = async (governmentId) => {
  try {
    const res = await API.graphql({
      query: getGovernmentCmd,
      variables: { governmentId },
      authMode: APP_AUTH_MODE,
    });
    return {
      success: true,
      data: res.data.getGovernment,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
}
export const updateGovernment = async (governmentId, newProps) => {
  try {
    const res = await API.graphql({
      query: updateGovernmentCmd,
      variables: {
        input: {
          governmentId,
          ...newProps
      } },
      authMode: APP_AUTH_MODE,
    });
    return {
      success: true,
      data: res.data.updateGovernment,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
}
export const extractDepartments = async (url) => {
  try {
    const res = await API.graphql({
      query: listDepartments,
      variables: {
        input: {
          url
        }
      },
      authMode: APP_AUTH_MODE,
    });
    return res.data.listDepartments;
  }
  catch (e) {
    return {
      success: false,
      error: e,
    };
  }
}
