export const customFindInternalConversationsByCityId = /* GraphQL */ `
    query FindInternalConversationsByCityId(
        $cityId: ID!
        $createdAt: ModelStringKeyConditionInput
        $filter: ModelInternalConversationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        findInternalConversationsByCityId(
            cityId: $cityId
            createdAt: $createdAt
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                cityId
                createdAt
                evaluation
                id
                name
                ownerId
                owner {
                    username
                    email
                    groups
                }
                updatedAt
                __typename
            }
            nextToken
            __typename
        }
    }
`;
export const customFindInternalConversationsByEvaluation = /* GraphQL */ `
  query FindInternalConversationsByEvaluation(
    $cityId: ID!
    $evaluation: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findInternalConversationsByEvaluation(
      cityId: $cityId
      evaluation: $evaluation
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        owner {
          username
          email
          groups
      }
        updatedAt
      }
      nextToken
    }
  }
`;
export const customFindInternalConversationsBySimilarity = /* GraphQL */ `
  query FindInternalConversationsBySimilarity(
    $cityId: ID!
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $similarity: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    findInternalConversationsBySimilarity(
      cityId: $cityId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      similarity: $similarity
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        similarity
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const customListExternalResources = /* GraphQL */ `
  query ListExternalResources(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalResourceFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExternalResources(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        answer
        url
        createdAt
        governmentId
        srcMsgKey
        name
        status
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const customListInternalResources = /* GraphQL */ `
  query ListInternalResources(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalResourceFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInternalResources(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        answer
        url
        createdAt
        governmentId
        srcMsgKey
        name
        status
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInternalConversationsByCityId = /* GraphQL */ `
  query FindInternalConversationsByCityId(
    $cityId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findInternalConversationsByCityId(
      cityId: $cityId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        ownerId
        updatedAt
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalConversationsByCityId = /* GraphQL */ `
  query FindExternalConversationsByCityId(
    $cityId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findExternalConversationsByCityId(
      cityId: $cityId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        ownerId
        updatedAt
      }
      nextToken
      __typename
    }
  }
`;
export const listAnalysis = /* GraphQL */ `
  query ListAnalyses(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelAnalysisFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalyses(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        currentDay
        external {
          conversations {
            bad
            good
            total
          }
          messages {
            disliked
            liked
            total
            totalNoAnswer
          }
          totalRegisteredUsers
        }
        internal {
          conversations {
            bad
            good
            total
          }
          messages {
            disliked
            liked
            total
          }
          totalRegisteredUsers
        }
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalMessages = /* GraphQL */ `
  query ListExternalMessages(
    $conversationId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExternalMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        conversationId
        createdAt
        role
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInternalMessages = /* GraphQL */ `
  query ListInternalMessages(
    $conversationId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInternalMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        conversationId
        createdAt
        role
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalConversations = /* GraphQL */ `
  query ListExternalConversations(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $ownerId: ID
    $sortDirection: ModelSortDirection
  ) {
    listExternalConversations(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerId: $ownerId
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        id
      }
      nextToken
      __typename
    }
  }
`;
export const listInternalConversations = /* GraphQL */ `
  query ListInternalConversations(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $ownerId: ID
    $sortDirection: ModelSortDirection
  ) {
    listInternalConversations(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerId: $ownerId
      sortDirection: $sortDirection
    ) {
      items {
        ownerId
        id
      }
      nextToken
      __typename
    }
  }
`;
