import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  question: PropTypes.string,
  answer: PropTypes.string,
  aiAnswer: PropTypes.string,
  url: PropTypes.string,
  tag: PropTypes.string,
  editable: PropTypes.bool,
};
const defaultProps = {
  isOpen: false,
  onClose: () => {},
  onUpdate: () => {},
  question: "",
  answer: "",
  aiAnswer: "",
  url: "",
  tag: "",
  editable: true,
};

const UpdateAnswerDialog = (props) => {
  const { isOpen, onClose, onUpdate, question, answer, aiAnswer, url, tag, editable } = props;
  const [fixAnswer, setFixAnswer] = useState(answer);
  const [fixUrl, setFixUrl] = useState(url);
  const [fixTag, setFixTag] = useState(tag);
  const [validated, setValidated] = useState(false);
  const [exceededLimit, setExceededLimit] = useState(false);
  useEffect(() => {
    setFixAnswer(answer);
  }, [answer]);

  useEffect(() => {
    setFixUrl(url);
  }, [url]);

  useEffect(() => {
    setFixTag(tag);
  }, [tag]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() && !exceededLimit) {
      onUpdate(fixAnswer, fixUrl, fixTag);
      setFixAnswer("");
      setFixUrl("");
      onClose();
    }
    setValidated(true);
  };

  const onFixAnswerChange = (event) => {
    const inputValue = event.target.value;
    setFixAnswer(inputValue);
    setExceededLimit(inputValue.length > 2000);
    setValidated(event.target.form.checkValidity() && !exceededLimit);
  };

  const isButtonDisabled = () => {
    if (tag!==fixTag) return false;
    return !validated || !editable || exceededLimit;
  };

  if (!isOpen) return null;

  return (
    <Modal show={isOpen} onHide={onClose} backdropClassName="modal show" centered>
      <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
        <Modal.Header closeButton onHide={onClose}>
          <Modal.Title className="">修正</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "256px", overflow: "auto" }}>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">質問</Form.Label>
            <Form.Control className="w-75" plaintext readOnly defaultValue={question} />
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">AI回答</Form.Label>
            <Form.Control className="w-75" as="textarea" rows={3} readOnly value={aiAnswer} />
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">回答</Form.Label>
            <div className="w-75">
              <Form.Control
                as="textarea"
                rows={3}
                value={fixAnswer}
                disabled={!editable}
                onChange={onFixAnswerChange}
                required
                isInvalid={exceededLimit}
              />
              <Form.Control.Feedback type="invalid">
                {exceededLimit && "回答は2000文字以下で入力してください。"}
              </Form.Control.Feedback>
            </div>
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">URL</Form.Label>
            <Form.Control
              className="w-75"
              as="textarea"
              rows={3}
              value={fixUrl}
              disabled={!editable}
              onChange={(e) => setFixUrl(e.target.value)}
            />
          </Container>
          <Container style={{ paddingBottom: "8px" }} className="d-flex align-items-center">
            <Form.Label className="w-25 m-0">タグ</Form.Label>
            <div className="w-75">
              <Form.Control
                as="textarea"
                rows={1}
                value={fixTag}
                disabled={!editable}
                onChange={(e) => setFixTag(e.target.value)}
              />
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end">
          <Button className="btn-outline btn-wd mr-1" variant="default" onClick={onClose}>
            キャンセル
          </Button>
          <Button
            className="btn-outline btn-wd mr-1"
            variant="default"
            disabled={isButtonDisabled()}
            onClick={() => {
              onUpdate(fixAnswer, fixUrl, fixTag);
            }}
          >
            保存
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

UpdateAnswerDialog.propTypes = propTypes;
UpdateAnswerDialog.defaultProps = defaultProps;
export default UpdateAnswerDialog;
