import React from 'react';
import './CategoryPage.css';
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { getGovernment, updateGovernment, extractDepartments } from '../../apis/GovernmentApi';
import { Button } from "react-bootstrap";
import { useDialog } from 'contexts/DialogContext';
import FanLoadingIcon from 'components/FanLoadingIcon';

const propTypes={};
const defaultProps={};
const CategoryPage = () => {
  /*** States and Variables ***/
  const {
    handleSubmit
  } = useForm();
  const { userInfo } = useSelector((state) => state.users);
  const governmentId = userInfo?.governmentId;
  const governmentRef = React.useRef(null);
  const [newDepartment, setNewDepartment] = React.useState('');
  const [departments, setDepartments] = React.useState([]);
  const formRef = React.useRef(null);
  const { success, error } = useDialog();
  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState('');
  /*** Processing ***/
  const reinitializeDepartments = async () => {
    if (!governmentRef.current) {
      const res = await getGovernment(governmentId);
      if (res.success) {
        governmentRef.current = res.data;
      }
    }
    setDepartments(governmentRef.current?.departments || []);
  }
  React.useEffect(() => {
    const getGovernmentData = async () => {
      await reinitializeDepartments();
    };
    getGovernmentData();
  }, [governmentId]);
  React.useEffect(() => {
    if (newDepartment) {
      setDepartments([...departments, newDepartment]);
      setNewDepartment('');
      setTimeout(() => {
        const formTag = formRef.current;
        const inputTags = formTag.querySelectorAll('.category_page_department_input');
        const inputtingTag = inputTags[departments.length];
        inputtingTag.focus();
      }, 50);
    }
  }, [newDepartment]);
  /*** Sub Components ***/
  const renderCategories = () => {
    const departmentViews = departments.map((category, index) => {
      const pos = category.indexOf('#');
      const name = pos < 0 ? category : category.substring(0, pos);
      const work = pos < 0 ? '' : category.substring(pos + 1);
      return (
        <div key={`${index}`} className="category_page_department_container">
          <input
            className="category_page_department_input"
            value={name}
            onChange={(e) => onDepartmentChange(e.target.value, work, index)}
          />
          <input
            className="category_page_department_work"
            placeholder="部署の業務内容を入力"
            value={work}
            onChange={(e) => onDepartmentChange(name, e.target.value, index)}
          />
        </div>
      );
    });
    departmentViews.push(
      <div className='category_page_department_container'>
        <input
          className='category_page_department_input'
          placeholder='新しい部署を追加'
          value={newDepartment}
          onChange={(e)=>setNewDepartment(e.target.value)}
        />
      </div>
    )
    return departmentViews;
  }
  /*** Event Handlers ***/
  const onSubmit = async () => {
    const res = await updateGovernment(governmentId, {departments});
    if (res.success) {
      success('保存しました');
      governmentRef.current = res.data;
    }
    else {
      error('保存に失敗しました');
    }
  };
  const onDepartmentChange = (name, work, index) => {
    if (name === '') {
      departments.splice(index, 1);
      setDepartments([...departments]);
    }
    else {
      departments[index] = `${name}#${work}`;
      setDepartments([...departments]);
    }
  }
  const onListDepartments = async () => {
    if (loading) return;
    setLoading(true);
    const res = await extractDepartments(url);
    if (res.success) {
      setDepartments(res.data);
    }
    else {
      error('部署の取得に失敗しました');
    }
    setLoading(false);
  }
  /*** Main Render ***/
  return (
    <form className="category_page_form_container" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <label className="category_page_title">部署タグ</label>
      <div>部署一覧を記載したサイトページから、タグに登録したい部署名を抽出できます。</div>
      <div className="category_page_note">
        （※生成AIで抽出しているため、一部の部署名などに誤りが生じる可能性があります）
      </div>
      <div className="category_page_search_contianer">
        <input
          className="category_page_search_input"
          value={url}
          placeholder="部署の一覧を記載したページのurlを入力"
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button className="category_page_search_button" variant="default" type="button" onClick={onListDepartments}>
          部署名を抽出
        </Button>
      </div>
      部署名・業務内容
      {renderCategories()}
      <div className="category_page_actions_contianer">
        <Button className="btn-outline btn-wd mr-1 category_page_button_save" variant="default" type="submit">
          保存
        </Button>
        <Button
          className="btn-outline btn-wd mr-1 category_page_button_save"
          variant="default"
          type="button"
          onClick={() => {
            setDepartments([]);
          }}
        >
          全削除
        </Button>
        <Button
          className="btn-outline btn-wd mr-1 category_page_button_save"
          variant="default"
          type="button"
          onClick={() => {
            reinitializeDepartments();
          }}
        >
          リセット
        </Button>
      </div>
      {loading && <FanLoadingIcon size={80} />}
    </form>
  );
}
CategoryPage.propTypes = propTypes;
CategoryPage.defaultProps = defaultProps;
export default CategoryPage;
